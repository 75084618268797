import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import { useDispatch, useSelector } from "react-redux";
import { listOrdersActions } from "../actions/orderActions";

function OrderListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const keyword = location.search;
  const baseUrl = "/admin/order-list/";

  const listOrders = useSelector((state) => state.listOrders);
  const { loading, error, orders, pages, page } = listOrders;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.is_admin) {
      
      dispatch(listOrdersActions(keyword));
    } else {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate, keyword]);

  return (
    <div>
      <h2 className="heading">Orders</h2>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Table striped bordered responsive hover className="table-sm">
            <thead className="th-res">
              <tr>
                <th>ID</th>
                <th>USER</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>{order.totalPrice}</td>
                  <td>
                    {order.isPaid ? (
                      order.paidAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times"></i>
                    )}
                  </td>
                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className="fas fa-times"></i>
                    )}
                  </td>
                  <td>
                    <Link to={`/order/${order._id}`}>
                      <Button className="btn-sm btn-res">Details</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate 
            page={page}
            pages={pages}
            isAdmin={true}
            baseUrl={baseUrl}
          />
        </div>
      )}
    </div>
  );
}

export default OrderListPage;
